import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navigation from './pages/components/Navigation';
import Footer from './pages/components/Footer';
import Loading from './pages/Loading';
import NotFound from './pages/NotFound';

// Lazy load components for authenticated routes
const Notes = lazy(() => import('./pages/components/Notes'));
const Documentation = lazy(() => import('./pages/Documentation'));
const Settings = lazy(() => import('./pages/Settings'));
const Dashboard = lazy(() => import('./pages/Dashboard'));

// Lazy load components for unauthenticated routes
const Login = lazy(() => import('./pages/Login'));
const Registration = lazy(() => import('./pages/Registration'));
const Verification = lazy(() => import('./pages/Verification'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const useRoutes = isAuthenticated => {
    const AuthenticatedRoutes = (
        <Router>
            <Navigation />
            <Notes />
            <Suspense fallback={<Loading/>}>
                <Routes>
                    <Route path="/" element={<Dashboard/>} />
                    <Route path="/documentation" element={<Documentation/>} />
                    <Route path="/settings" element={<Settings/>} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
            <Footer />
        </Router>
    );

    const UnauthenticatedRoutes = (
        <div className="form-back">
            <Suspense fallback={<Loading/>}>
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/create-account" element={<Registration />} />
                    <Route path="/verification" element={<Verification />} />
                    <Route path="*" element={<Login />} />
                </Routes>
            </Router>
            </Suspense>
        </div>
      );

  return isAuthenticated ? AuthenticatedRoutes : UnauthenticatedRoutes;
};

export default useRoutes;