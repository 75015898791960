import '../styles/loading.css';

function Loading({ top }) { 
  return <div className="loader">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
  </div>;
}

export default Loading;