import React from 'react';
import '../styles/notFound.css';
import { ReactComponent as NotFoundImg } from '../styles/images/not_found.svg';

const NotFound = () => {
    return (
        <div className="middle">
            <h1>Page not found</h1>
            <NotFoundImg style={{ width: '400px', height: '350px' }}/>
        </div>
    );
};

export default NotFound;